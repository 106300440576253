import { render, staticRenderFns } from "./Cashback.vue?vue&type=template&id=274524f4&scoped=true&"
import script from "./Cashback.vue?vue&type=script&lang=js&"
export * from "./Cashback.vue?vue&type=script&lang=js&"
import style0 from "./Cashback.vue?vue&type=style&index=0&id=274524f4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274524f4",
  null
  
)

export default component.exports